import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import Bg from "./bg-component";
import { useUserContext } from "../context/authcontext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {    

  const {updateUser}=useUserContext();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [TeamName, setTeamName] = useState("");
 
  
    
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const { data } = await axios.post(
          "/api/v1/login",
          {
            TeamName,
            password
          },
          { withCredentials: true }
        );
        const { success, message,Authdata } = data;
        if (success) {
          toast("Login Successfull!")

          console.log(message);
          updateUser(Authdata)
          
          setTimeout(() => {
            navigate("/home");
          }, 2000);
        } else {

          toast({message})

        } 
      } catch (error) {
        console.log(error);
      }
     
    };
   


  return (
    <div className="">
                            <Bg/>
                            <div className="d-flex justify-content-center align-items-center  min-h-dvh">
                            <ToastContainer className='absolute z-2 right-0 mt-48' />

        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                    bg-opacity-20 backdrop-filter backdrop-blur-md
                    p-6 rounded-xl shadow-lg border-[2px] border-[#006FEF] border-opacity-30  w-[80%]
                    md:w-[40%] lg:w-[30%] sm:w-[70%]  text-center flex flex-wrap flex-col items-center text-white">
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                
                <div className="mb-4 my-4 text-2xl  flex flex-wrap">
                    <h3>
                        <strong>Team Name</strong>
                    </h3>
                    <input type="text" 
                    placeholder='Enter Team Name' 
                    autoComplete='off' 
                    name='TeamName' 
            
                    className='form-control rounded-lg' 
                    onChange={(e) => setTeamName(e.target.value)}

                    />
                </div>
                <div className="mb-4 my-4 text-2xl  flex flex-wrap">
                    <h3>
                        <strong>Password</strong>
                    </h3>
                    <input type="password" 
                    placeholder='Enter Password' 
                    name='password' 
                    value={password}
                    className='form-control rounded-lg' 
                    onChange={(e) => setPassword(e.target.value)}

                    />
                </div>
                <button type="submit"  className="p-2 mb-3 px-4 text-decoration-none text-white  font-medium transition-all duration-150 delay-75 border-2 rounded-lg max-[370px]:text-xs  border-[#006FEF] bg-[#00BCFC] hover:bg-transparent hover:text-white font-poppins">
                    Login
                </button>
                </form>
                <p>Don't have an account?</p>
                <Link to="/signup" className="p-2 px-5 text-decoration-none text-white font-medium transition-all duration-150 delay-75 border-2 rounded-lg text-sm border-[#006FEF] hover:bg-[#00BCFC] hover:text-white font-poppins ">
                    Sign Up
                </Link>
            </div>
        </div>

    </div>
  );
}

export default Login;