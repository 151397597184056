import React from "react";
import Bg from "./bg-component";
 import { useState, useEffect } from "react";
import Cookies from 'js-cookie';

function Home() {
    const [teamName, setTeamName] = useState('');

    useEffect(() => {
        // Retrieve the team name from the cookie
        const cookieTeamName = Cookies.get('TeamName');
        if (cookieTeamName) {
            setTeamName(cookieTeamName);
        }
    }, []);

    return (
        <div className="" id="">
            <Bg />
            <div className="min-h-dvh align-content-center text-center position-relative text-white z-1">
            <h2 className="font-handjet text-6xl py-4">{teamName}
            </h2>
            <div>
                    <p className="font-bold text-[#00BCFC] text-6xl font-roboto">IEEE IPEC</p>
                </div>
                <h1 className="font-handjet text-6xl">Enigma '24</h1>
                <div className="pt-10">
                    <a href="/hackem" className="p-2 px-4 text-decoration-none text-white font-medium transition-all duration-150 delay-75 border-2 rounded-lg max-[370px]:text-xs border-[#006FEF] hover:bg-[#00BCFC] hover:text-white font-poppins">
                        Join the Fun
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Home;