import { useEffect } from 'react'
import NET from 'vanta/src/vanta.net'

function Bg(){
    useEffect(()=>{
        NET({
            el: "#netbg",
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: '#0c4693',
            spacing: 15.00,
            points:10
          })
    },[])
return(
    <div className='h-full w-full position-absolute z-0 ' id='netbg'>
        
    </div>
)
}

export default Bg