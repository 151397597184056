import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";


function QuestionPills() {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [TeamName, setTeamName] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answer, setAnswer] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyCookie = async () => {
      if (!cookies.token) {
        navigate("/");
        return;
      }
      const { data } = await axios.post(
        "/api/v1",
        {},
        { withCredentials: true }
      );
      const { status, TeamName } = data;
      setTeamName(TeamName);
      if (!status) {
        removeCookie("token");
        navigate("/");
      } 
    };    
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
   const fetchQuestions = async () => {
  try {
    const response = await axios.get("/api/v1/questions", { withCredentials: true });
    if (response.data.success) {
      const sortedQuestions = response.data.questions.sort((a, b) => {
        const order = { 'Easy': 1, 'Medium': 2, 'Its Over 9000': 4,'Hard': 3 };
        return order[a.difficulty] - order[b.difficulty];
      });
      setQuestions(sortedQuestions);
    } 
  } catch (error) {
    console.error("Error fetching questions:", error);
  }
};


    fetchQuestions();
  }, []);

  

  const handlePillClick = (question) => {
    setSelectedQuestion(question);
    setShowPopup(true);
    setAnswer("");
    setMessage("");
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSubmitAnswer = async () => {
    try {
      const response = await axios.post("/api/v1/update-score", {
        
        TeamName:cookies.TeamName,
        questionId: selectedQuestion.id,
        answer
      }, { withCredentials: true });

      if (response.data.success) {
        setMessage("Correct answer! Your score has been updated.");
      } else {
        setMessage("Incorrect answer. Try again!");
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  const getDifficultyColor = (difficulty) => {
    switch (difficulty) {
      case 'Easy':
        return 'text-green-500';
      case 'Medium':
        return 'text-yellow-500';
      case 'Hard':
        return 'text-red-500';
      default:
        return 'text-amber-800';
    }
  };

  return (
    <div>
      <div className="bg-[#23153C] min-h-screen p-8 ">
        <div className='items-center min-h-screen flex justify-center flex-col flex-wrap mt-[10%] '>
          {questions.map((difficulty) => (
            <div key={difficulty.difficulty} className="mb-8 flex justify-center items-center flex-wrap flex-col w-[50%]">
              <h3 className={`text-2xl font-bold mb-4 ${getDifficultyColor(difficulty.difficulty)}`}>
                {difficulty.difficulty}
              </h3>
              {difficulty.questions.map((question) => (
                <div key={question.id} className="w-full lg:w-3/4 px-2 mb-4">
                  <button
                    className={`w-full bg-gray-800 hover:bg-gray-700 ${getDifficultyColor(difficulty.difficulty)} font-semibold py-3 px-4 rounded-lg transition duration-300 ease-in-out`}
                    onClick={() => handlePillClick(question)}
                  >
                    {question.title}
                  </button>
                </div>
              ))}
            </div>
          ))}
        </div>
        {showPopup && (
          <div
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                      bg-opacity-20 backdrop-filter backdrop-blur-lg 
                      p-6 rounded-xl shadow-lg border border-white border-opacity-30 w-[80%] 
                      md:w-2/4 h-fit       text-center flex flex-col justify-center items-center"
            style={{ zIndex: 1000 }}
          >
            <h2 className="text-3xl text-white font-bold mb-4">{selectedQuestion.title}</h2>
            <p className="text-gray-300 mb-4">{selectedQuestion.description}</p>
            <img src={selectedQuestion.media}  className="mb-2 max-w-36"></img>

            <a href={selectedQuestion.link} target='blank' className="text-gray-300 mb-4">{selectedQuestion.linkdes}</a>
            <input
              type="text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              placeholder="Enter your answer"
              className=" p-2 mb-4 bg-gray-700 text-white rounded  md:w-1/4 w-[50%]"
            />
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-5 rounded "
              onClick={handleSubmitAnswer}
            >
              Submit Answer
            </button>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleClosePopup}
            >
              Close
            </button>
            {message && <p className="mt-4 text-white">{message}</p>}
          </div>
        )}
      </div>
    </div>
  );
}

export default QuestionPills;
