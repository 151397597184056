import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

function Leaderboard() {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [teams, setTeams] = useState([]);
  const [sortedTeams, setSortedTeams] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyCookie = async () => {
      if (!cookies.token) {
        navigate("/");
        return;
      }
      const { data } = await axios.post("/api/v1", {}, { withCredentials: true });
      const { status, user } = data;
      setUsername(user);
      if (!status) {
        removeCookie("token");
        navigate("/");
      }
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const Logout = () => {
    removeCookie("token");
    navigate("/signup");
  };

  // Fetch team scores and update sortedTeams on data change
  useEffect(() => {
    const fetchTeamScores = async () => {
      try {
        const response = await axios.get('/api/v1/teamscores');
        if (response.data.success) {
          // Convert object to array and update teams
          const teamsArray = Object.values(response.data.teamScores);
          setTeams(teamsArray);

          // Sort teams right after fetching and setting
          const sorted = [...teamsArray].sort((a, b) => b.Score - a.Score);
          setSortedTeams(sorted);
        } else {
          console.log(response.data.message);
          setError('Failed to fetch team scores');
        }
      } catch (error) {
        setError('Error fetching team scores: ' + error.message);
      }
    };

    fetchTeamScores();
    const intervalId = setInterval(fetchTeamScores, 10000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const teamsPerPage = 10;
  const indexOfLastTeam = currentPage * teamsPerPage;
  const indexOfFirstTeam = indexOfLastTeam - teamsPerPage;
  const currentTeams = sortedTeams.slice(indexOfFirstTeam, indexOfLastTeam);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='min-h-screen bg-gradient-to-b from-[#23153C] to-[#1C1034] text-white'>
      <div className="flex flex-col items-center max-w-6xl min-h-screen mx-auto p-8">
        <header className="w-full text-center  my-12">
          <h1 className="text-6xl font-bold text-[#006FEF] font-handjet">Leaderboard <br></br><span className='text-4xl'> | Updates Every 5 Minute |</span> </h1>
        </header>
        
        <div className="w-full bg-[#2D1D4A] rounded-lg shadow-lg overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className='bg-[#3A2463] text-[#00BCFC]'>
                <th className="p-3 text-left">Rank</th>
                <th className="p-3 text-left">Team Name</th>
                <th className="p-3 text-left">Score</th>
              </tr>
            </thead>
            <tbody>
              {currentTeams.map((team, index) => (
                <tr
                  key={team.id || `team-${indexOfFirstTeam + index}`}
                  className={`${index % 2 === 0 ? 'bg-[#2D1D4A]' : 'bg-[#352358]'} hover:bg-[#4A2F7C] transition-colors duration-150`}
                >
                  <td className="p-3">{indexOfFirstTeam + index + 1}</td>
                  <td className="p-3">{team.TeamName}</td> 
                  <td className="p-3">{team.Score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination flex mt-6 space-x-2">
          {Array(Math.ceil(teams.length / teamsPerPage)).fill(0).map((_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`px-4 py-2 rounded ${
                currentPage === index + 1 
                  ? 'bg-[#00BCFC] text-[#23153C]' 
                  : 'bg-[#2D1D4A] text-white hover:bg-[#3A2463]'
              } transition-colors duration-150`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Leaderboard;
