import '../about.css'
const About=() => {

    return(
<div className='bg-[#23153C] min-h-dvh'>

<section className="team-section">
        <h1 className='text-white mt-[100px] font-poppins'>Meet Our Team</h1>

        <div className="team-row">
            <div className="team-member">
                <img src="pfp.jpeg" alt="Team Member 1"/>
                <h2>Shantanu Pant</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit</p>
                <div className="social-links">
                    <a href="#"><img src="gma.png" alt="Gmail Logo"/></a>
                    <a href="#"><img src="linkedin.png" alt="LinkedIn Logo"/></a>
                    <a href="#"><img src="github.png" alt="GitHub Logo"/></a>
                </div>
            </div>

            <div className="team-member">
                <img src="pfp.jpeg" alt="Team Member 2"/>
                <h2>Saanvi Verma</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                <div className="social-links">
                    <a href="#"><img src="gma.png" alt="Gmail Logo"/></a>
                    <a href="#"><img src="linkedin.png" alt="LinkedIn Logo"/></a>
                    <a href="#"><img src="github.png" alt="GitHub Logo"/></a>
                </div>
            </div>

            <div className="team-member">
                <img src="pfp.jpeg" alt="Team Member 3"/>
                <h2>Anvy Maheshwari</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                <div className="social-links">
                    <a href="#"><img src="gma.png" alt="Gmail Logo"/></a>
                    <a href="#"><img src="linkedin.png" alt="LinkedIn Logo"/></a>
                    <a href="#"><img src="github.png" alt="GitHub Logo"/></a>
                </div>
            </div>
        </div>

        <div className="team-row">
            <div className="team-member">
                <img src="pfp.jpeg" alt="Team Member 4"/>
                <h2>Sidhi Jain</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit</p>
                <div className="social-links">
                    <a href="#"><img src="gma.png" alt="Gmail Logo"/></a>
                    <a href="#"><img src="linkedin.png" alt="LinkedIn Logo"/></a>
                    <a href="#"><img src="github.png" alt="GitHub Logo"/></a>
                </div>
            </div>
            <div className='hidden'> 
            <div className="  team-member">
                <img src="pfp.jpeg" alt="Team Member 5"/>
                <h2>Imposter</h2>
                <p>He's a sussy imposter did you caught him?</p>
                <div className="social-links">
                    <a href="https://pastebin.com/Z0BbaQnF"><img src="gma.png" alt="Gmail Logo"/></a>
                    <a href="https://pastebin.com/Z0BbaQnF"><img src="linkedin.png" alt="LinkedIn Logo"/></a>
                    <a href="https://pastebin.com/Z0BbaQnF"><img src="github.png" alt="GitHub Logo"/></a>
                </div>
            </div>

            </div>
            <div className="team-member">
                <img src="pfp.jpeg" alt="Team Member 6"/>
                <h2>Riya Gusain</h2>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit</p>
                <div className="social-links">
                    <a href="#"><img src="gma.png" alt="Gmail Logo"/></a>
                    <a href="#"><img src="linkedin.png" alt="LinkedIn Logo"/></a>
                    <a href="#"><img src="github.png" alt="GitHub Logo"/></a>
                </div>
            </div>
        </div>

    </section>
</div>
    );
};
export default About;