import React, { createContext, useState, useContext } from 'react';

// Create the context
const UserContext = createContext();

// Custom hook to use the UserContext
export const useUserContext = () => {
    if(!UserContext) throw new Error("User Context not defined")
    return useContext(UserContext);
};

// Context provider component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(undefined);

    const updateUser = (data) => {
        setUser(data);
    };

    const resetUser = () => {
        setUser(undefined);
    };

    return (
        <UserContext.Provider value={{ user,setUser , updateUser, resetUser }}>
            {children}
        </UserContext.Provider>
    );
};