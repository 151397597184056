import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter, Routes, Route, createBrowserRouter, RouterProvider} from 'react-router-dom'
import Nav from './components/nav/navbar'
import Signup from './components/signup'
import Login from './components/login'
import Home from './components/home'
import About from './components/about'
import Footer from './components/nav/footer'
import QuestionPills from './components/questions'
import Leaderboard from './components/leaderboard'
import MainLayout from './MainLayout'
function App() {
  const router = createBrowserRouter([
    
    {
      path:"/",
      element:<MainLayout/>,
      children: [
        {
          path: "/",
          element:<Login/>,
        },
        {
          path: "/signup",
          element:<Signup/>,
        },
        {
          path: "/leaderboard",
          element:<Leaderboard/>
        },
        {
          path: "/hackem",
          element:<QuestionPills/>
        },
        {
          path: "/home",
          element:<Home/>
        },
        
        {
          path: "/aboutus",
          element:<About/>
        },
        
        
      ],
    },
  ]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  )
}

export default App