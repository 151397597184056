import { Outlet } from "react-router-dom";
import { AuthNavbar, Navbar } from "./components/nav/navbar";
import Footer from "./components/nav/footer.jsx";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

function MainLayout() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies] = useCookies(["token"]);

  useEffect(() => {
    setIsAuthenticated(!!cookies.token);
  }, [cookies.token]);

  return (
    <div className="">
      {isAuthenticated ? <AuthNavbar /> : <Navbar />}
      <div className="z-10">
        <Outlet />
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
}

export default MainLayout;
