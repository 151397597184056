import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUserContext } from '../../context/authcontext';
export const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

 

  return (
    <div className='fixed w-full z-50 flex md:justify-between '>
      <div className='flex justify-between p-4 '>
          <img src='../assets/logo.png' alt="Logo" className="hidden md:block w-[20%] m-3  flex  z-1" /> 
        <button className="md:hidden text-white text-3xl" onClick={toggleDrawer}>
          ☰
        </button>
    </div>
    
      <div className={`fixed inset-0 bg-[#23153C] transform transition-transform duration-300 ease-in-out ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'} md:relative md:translate-x-0 md:bg-transparent md:flex md:justify-end`}>
      <div className="fixed flex justify-end p-4 md:hidden ">
      <button className="text-white text-3xl" onClick={toggleDrawer}>×</button>
        </div>
        <ul className='flex flex-col md:flex-row justify-center md:justify-end items-center space-y-8 md:space-y-0 md:space-x-6 h-full md:h-auto mr-5'>
          <li><Link to="/home" className="text-white hover:text-t1 font-bold no-underline text-2xl md:text-base p-4" onClick={toggleDrawer}>Home</Link></li>
          <li><Link to="/aboutus" className="text-white hover:text-t1 font-bold no-underline text-2xl md:text-base p-4" onClick={toggleDrawer}>About Us</Link></li>
          <li>
            <Link to="/" onClick={toggleDrawer} className='p-2 px-4 ml-2 text-decoration-none text-white font-medium transition-all duration-150 delay-75 border-2 rounded-lg max-[370px]:text-xs border-[#006FEF] bg-[#00BCFC] hover:bg-transparent hover:text-white font-poppins'>
              <button>LogIn</button>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export const AuthNavbar = () => {
  const { resetUser } = useUserContext();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function handleLogout() {
    axios.post("/api/v1/logout")
      .then(res => {
        navigate("/");
        resetUser();
      })
      .catch(err => console.log(err));
  }

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Prevent scrolling when drawer is open
 
  return (
    <div className='fixed w-full z-50 flex md:justify-between '>
      <div className='flex justify-between p-4'>
      <img src='../assets/logo.png' alt="Logo" className="hidden md:block w-[20%] m-3  flex  z-1" />

        <button className="md:hidden text-white text-3xl" onClick={toggleDrawer}>
          ☰
        </button>
      </div>
      
      {/* Full-screen Drawer Navigation */}
       <div className={`fixed inset-0 bg-[#23153C] transform transition-transform duration-300 ease-in-out ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'} md:relative md:translate-x-0 md:bg-transparent md:flex md:justify-end`}>
      <div className="fixed flex justify-end p-4  md:hidden">
          <button className="text-white text-2xl" onClick={toggleDrawer}>×</button>
        </div>
        <ul className='flex flex-col md:flex-row justify-center md:justify-end items-center space-y-8 md:space-y-0 md:space-x-6 h-full md:h-auto mr-5'>
          <li><Link to="/home" className="text-white hover:text-t1 font-bold no-underline text-2xl md:text-lg" onClick={toggleDrawer}>Home</Link></li>
          <li><Link to="/hackem" className="text-white hover:text-t1 font-bold no-underline text-2xl md:text-base" onClick={toggleDrawer}>Arena</Link></li>
          <li><Link to="/leaderboard" className="text-white hover:text-t1 font-bold no-underline text-2xl md:text-base" onClick={toggleDrawer}>Leaderboard</Link></li>
          <li><Link to="/aboutus" className="text-white hover:text-t1 font-bold no-underline text-2xl md:text-base" onClick={toggleDrawer}>About Us</Link></li>
          <li>
            <button 
              onClick={() => { handleLogout(); toggleDrawer(); }}
              className='p-2 px-4 text-white text-2xl md:text-lg font-medium transition-all duration-150 delay-75 border-2 rounded-lg border-[#006FEF] bg-[#00BCFC] hover:bg-transparent hover:text-white font-[CustomFont]'
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

